/* tslint:disable:no-default-export */

import React from 'react';

import LayoutDefault from '../../../components/capital/Layouts/LayoutDefault';
import { Glossary } from '../../../components';

import systemGlossary from '../../../data/glossary/system.json';

import tocStyles from '../../../components/capital/Layouts/TOC.scss';

export default () => (
  <LayoutDefault
    activeTab="overview"
    title="System Glossary"
    description="Terms used within and to describe Capital Design System."
    wide={true}
  >
    <Glossary terms={systemGlossary.terms} />
  </LayoutDefault>
);
